/* eslint-disable no-undef */

'use strict';

/**
 * @description create Subscription
 * @returns {void}
 */

module.exports = {
	createSubscription: function () {
		$(document).ready(function () {
			/**
			 * @description Handles the result of the subscription call
			 * @param {Object} response, result of the subscription call
			 */
			function handleSubscriptionResponse(response) {
				if (response && response.success) {
					switch (response.accountStatus) {
						case 'accountexists':
							window.location.href = EmarsysUrls.alreadyRegisteredPage;
							break;
						case 'accountcreated':
							window.location.href = EmarsysUrls.thankYouPage;
							break;
						case 'submitted':
							window.location.href = EmarsysUrls.dataSubmittedPage;
							break;
						case 'error':
							window.location.href = EmarsysUrls.errorPage;
							break;
						case 'disabled':
							window.location.href = EmarsysUrls.emarsysDisabledPage;
							break;
						case 'signup':
							window.location.href = EmarsysUrls.emarsysSignup;
							break;
						default:
							break;
					}
				} else {
					window.location.href = EmarsysUrls.errorPage;
				}
			}

			function validateEmail(email) {
				var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
				return regex.test(email);
			}
			/**
			 * @description Does a subscription call
			 * @param {Strin} email , subscriber email
			 */
			function sendSubscriptionRequest(email) {
				$.ajax({
					type: 'POST',
					url: EmarsysUrls.footerSubscription,
					data: { emailAddress: email, formatajax: true },
					success: function (response) {
						handleSubscriptionResponse(response);
					},
					error: function () {
						window.location.href = EmarsysUrls.errorPage;
					}
				});
			}

			$('#emarsys-newsletter-subscription button').on('click', function (e) {
				e.preventDefault();
				var email = $('#emarsys-newsletter-subscription #email-alert-address').val();
				$('#emarsys-newsletter-subscription').find('.newsletter-form.error').removeClass('error');
				if (email.length > 0) {
					if (validateEmail(email)) {
						sendSubscriptionRequest(email);
					} else {
						$(this).closest('.newsletter-form').addClass('error');
					}
				}
			});

			// Add a checkbox for privacy policy if Emarsys is enabled
			if (window.EmarsysPreferences.enabled && window.EmarsysPreferences.enabled !== false) {
				var elementBefore = '<input type="checkbox" class="input-checkbox privacy-checkbox" value="false" /> ' + window.EmarsysResources.privacyBeforeLink + ' ';
				var elementAfter = ' ' + window.EmarsysResources.privacyAfterLink;

				$('a.privacy-policy').parent().prepend(elementBefore).append(elementAfter);

				$('#dwfrm_billing').on('submit', function (event) {
					if ($(this).find('input.privacy-checkbox').prop('checked') === false) {
						event.preventDefault();
						$('input.privacy-checkbox').parent().addClass('error-privacy');
					} else {
						$('input.privacy-checkbox').parent().removeClass('error-privacy');
					}
				});
				$('#account_subscribe').on('click', function (event) {
					event.preventDefault();
					$('.email-signup-wrapper').slideDown(800);
				});
			}
		});
	}
};
