'use strict';

if (!window.$) {
	// eslint-disable-next-line no-multi-assign
	window.jQuery = window.$ = require('jquery');
}

var processInclude = require('emarsys/util');

$(document).ready(function () {
	processInclude(require('emarsys/emarsysAnalytics'));
	processInclude(require('./emarsys/emarsysSubscription'));
});
